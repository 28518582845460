
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server) return;

  const userId = useCurrentUID();

  if (userId == null) {
    console.log("No user Id?");
    return navigateTo({
      path: "/",
    });
  }

  const classroomId = to.params.classroomId;

  if (classroomId == undefined) {
    return navigateTo({
      path: "/",
    });
  }

  const userClassroomStore = useUserClassroomDataStore(classroomId as string);

  if (userClassroomStore.isInitialized == true) {
    const classroom = userClassroomStore.classroom;

    if (classroom == undefined) {
      return navigateTo({
        path: "/",
      });
    }

    if (classroom.isArchived == true) {
      return navigateTo({
        path: "/",
      });
    }
  }

  if (
    useCapabilitiesServiceStore().isInitialized &&
    useCapabilitiesServiceStore().hasEnoughClassroomLicenses != true
  ) {
    return navigateTo({
      path: "/configure/lms/classrooms",
    });
  }
});
